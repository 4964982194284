import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IS_HANDSET } from '@memberspot/frontend/shared/feature/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@UntilDestroy()
@Component({
  selector: 'mspot-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  breadcrumbs$ = this._breadcrumbService.breadcrumbs$;

  constructor(
    @Inject(IS_HANDSET) public isHandset$: Observable<boolean>,
    private _breadcrumbService: BreadcrumbService,
  ) {}
}
