import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { IS_ADMIN } from '@memberspot/frontend/shared/util/tokens';
import { timer } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';

import { SoftwareUpdateService } from '../software-update.service';

@Component({
  selector: 'mspot-software-update',
  templateUrl: './software-update.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareUpdateComponent {
  timer$ = timer(1000, 1000).pipe(
    map((t) => 14 - t),
    takeWhile((t) => t > 0),
    tap((t) => {
      if (t === 1) {
        this.refresh();
      }
    }),
  );

  constructor(
    private softwareUpdateService: SoftwareUpdateService,
    @Optional() @Inject(IS_ADMIN) public isAdmin: boolean,
  ) {}

  refresh() {
    document.location.reload();
  }

  snooze() {
    this.softwareUpdateService.snooze.next(true);
  }
}
