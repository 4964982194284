/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

export const hubspotAppRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const code = route.queryParamMap.get('code');
  const schoolId = route.queryParamMap.get('state');
  const error = route.queryParamMap.get('error');

  if (schoolId && (code || error)) {
    return inject(Router).createUrlTree(
      [schoolId, 'settings', 'integrations', 'add', 'hubspot'],
      {
        queryParams: {
          code,
          error,
        },
      },
    );
  }

  return true;
};
