import { Provider } from '@angular/core';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { SCHOOL_ID } from '@memberspot/frontend/shared/data-access/common';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export const SCHOOL_ID_PROVIDER: Provider = {
  provide: SCHOOL_ID,
  deps: [SchoolsQuery],
  useFactory: schoolIdFactory,
};

export function schoolIdFactory(
  schoolsQuery: SchoolsQuery,
): Observable<string> {
  return schoolsQuery.selectActiveId().pipe(
    distinctUntilChanged(),
    filter((id) => !!id),
  );
}
