<div class="login-register">
  <div class="login-register-box error-card text-center">
    <h1>404</h1>
    <h3 class="text-uppercase">Page Not Found !</h3>
    <p class="mb-7 mt-7 text-gray-400">
      You seem to be trying to find this way to home
    </p>
    <a [routerLink]="['/']" class="btn btn-primary mb-2">Back to home</a>
  </div>
</div>
