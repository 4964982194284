import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { INTERCOM_CONFIG, IntercomConfig } from './config/intercom-config';
import { IntercomService } from './intercom.service';

@NgModule({
  imports: [CommonModule],
})
export class FrontendSharedUtilIntercomModule {
  static forRoot(
    config: IntercomConfig,
  ): ModuleWithProviders<FrontendSharedUtilIntercomModule> {
    return {
      ngModule: FrontendSharedUtilIntercomModule,
      providers: [
        IntercomService,
        { provide: INTERCOM_CONFIG, useValue: config },
      ],
    };
  }
}
