import { Inject, Injectable, InjectionToken, NgZone } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PlatformService } from '@memberspot/frontend/shared/util/common';
import { WINDOW } from '@ng-web-apis/common';
import { asyncScheduler, BehaviorSubject } from 'rxjs';
import { filter, observeOn, scan, tap } from 'rxjs/operators';

export const SCROLL_TOP_DISTANCE = new InjectionToken<string>(
  'Scroll Top Distance',
);

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private _isPopstateSubj = new BehaviorSubject(false);

  isPopstate$ = this._isPopstateSubj.asObservable();

  constructor(
    private _router: Router,
    private _zone: NgZone,
    @Inject(SCROLL_TOP_DISTANCE) private _scrollTopDistance: number,
    @Inject(WINDOW) private _window: Window,
    private _platformService: PlatformService,
  ) {
    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
        scan<any, any>((acc, event) => ({
          event,
          positions: {
            ...acc.positions,
            ...(event instanceof NavigationStart
              ? {
                  [event.id]: this._window.scrollY,
                }
              : ({} as any)),
          },
          trigger:
            event instanceof NavigationStart
              ? event.navigationTrigger
              : acc.trigger,
          idToRestore:
            (event instanceof NavigationStart &&
              event.restoredState &&
              event.restoredState.navigationId + 1) ||
            acc.idToRestore,
        })),
        filter(({ event }) => event instanceof NavigationEnd),
        tap(({ trigger }) => this._isPopstateSubj.next(trigger === 'popstate')),
        observeOn(asyncScheduler),
      )
      .subscribe(({ trigger, positions, idToRestore }) => {
        this._zone.runOutsideAngular(() => {
          setTimeout(() => {
            if (trigger === 'popstate') {
              this._window.scrollTo({ top: positions[idToRestore] });
            } else {
              const tree = _router.parseUrl(_router.url);
              const fragment = tree?.fragment;
              const { noScroll, portal } = tree.queryParams;

              if (fragment) {
                if (!this._platformService.SAFARI) {
                  this.scrollToId(fragment);
                }
              } else if ((!noScroll || noScroll === 'false') && !portal) {
                this._window.scrollTo({ top: 0, behavior: 'auto' });
              }
            }
          }, 20);
        });
      });
  }

  scrollToId(id: string) {
    const el = document.getElementById(id);
    const top = this.getBoundingClientRectTop(el) - this._scrollTopDistance;

    this._window.scrollTo({ top, behavior: 'auto' });
  }

  private getBoundingClientRectTop(element: HTMLElement | null): number {
    return element?.getBoundingClientRect()?.top || 0;
  }
}
