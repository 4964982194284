import { ChangeDetectionStrategy, Component } from '@angular/core';

import { VERSION } from '../../../../../../../../../version';

@Component({
  selector: 'mspot-version',
  template: `
    <div class="bg-white text-gray-700">
      <p>Version: {{ version?.version }}</p>
      <p>GitHash: {{ version?.hash }}</p>
      <p>Tag: {{ version?.tag }}</p>
      <p>Date: {{ version?.date }}</p>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionComponent {
  version = VERSION;

  constructor() {}
}
