import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { RouteData } from '@memberspot/admin/shared/util/model';
import { FrontendSharedUiBreadcrumbModule } from '@memberspot/frontend/shared/ui/breadcrumb';
import { map } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [FrontendSharedUiBreadcrumbModule, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBreadcrumbComponent {
  breadcrumbs$ = this._breadcrumbService.breadcrumbs$;
  maxWidth$ = this._routerQuery
    .selectData()
    .pipe(
      map((data: RouteData) =>
        data?.maxWidth ? `${data.maxWidth}px` : '1280px',
      ),
    );

  constructor(
    private _breadcrumbService: BreadcrumbService,
    private _routerQuery: RouterQuery,
  ) {}
}
