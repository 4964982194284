import { ApplicationRef, inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { WINDOW } from '@ng-web-apis/common';
import { interval, ReplaySubject } from 'rxjs';

import { UnsupportedVersionService } from './unsupported-version.service';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallService {
  private _subj = new ReplaySubject(1);
  private _window = inject(WINDOW);

  deferredPrompt: any;

  appNotInstalled$ = this._subj.asObservable();

  constructor(
    private _swUpdate: SwUpdate,
    private _appRef: ApplicationRef,
    private _unsupportedVersionService: UnsupportedVersionService,
  ) {
    this._window.addEventListener('beforeinstallprompt', (event) => {
      this.deferredPrompt = event;
      this._subj.next(true);
    });

    //Only production
    this.initUpdateCheck();
    this._unsupportedVersionService.initUnsupportedVersionCheck();
  }

  initUpdateCheck() {
    // const appIsStable$ = this._appRef.isStable.pipe(
    //   rxDebug('appIsStable'),
    //   first((isStable) => isStable === true)
    // );
    const everyThreeHours$ = interval(3 * 60 * 60 * 1000);
    // const everySixHoursOnceAppIsStable$ = concat(
    //   appIsStable$,
    //   everyThreeHours$
    // ).pipe(filter((upt) => upt !== true));

    everyThreeHours$.subscribe(async () => {
      try {
        await this._swUpdate.checkForUpdate();
      } catch (error) {
        console.log(error);
      }
    });
  }

  install() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then(
        (choiceResult: { outcome: string }) => {
          if (choiceResult.outcome === 'accepted') {
            this.deferredPrompt = null;
            this._subj.next(false);
          } else {
            this._subj.next(false);
          }
        },
      );
    }
  }
}
