import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { combineLatest, map, Observable } from 'rxjs';
import { filter, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';

import { MENU_ITEMS, MENU2ITEMS } from '../../menu-items/model/menu-items';
import { Menu } from '../../menu-items/model/menu-items.model';

const FLAT_MENU_ITEMS = _getFlattenedMenuItem([...MENU_ITEMS, ...MENU2ITEMS]);

const BILLING_ITEMS_TO_CHECK_FOR_MENU_PARAM = [
  'settings/billing/pricing',
  'settings/billing/account',
  'settings/billing/connect-accounts',
];

@Injectable({
  providedIn: 'root',
})
export class SidebarActiveService {
  private _schoolId$ = inject(SchoolsQuery)
    .selectActiveId()
    .pipe(filter((id): id is string => !!id));

  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  activeMenuItem$: Observable<Menu | undefined> = combineLatest([
    this._router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      startWith(null),
    ),
    this._schoolId$,
  ]).pipe(
    withLatestFrom(this._activatedRoute.queryParamMap),
    map(([[, schoolId], queryParams]) => {
      const url = this._router.url;

      if (_isOnBillingRoute(url, schoolId) && !queryParams.get('menu')) {
        /**
         * When we navigate to any page via settings (indicated by missing menu param), we want to highlight the settings menu item.
         */
        return _getMenuItemByUrl(BASE_PATH(schoolId, 'settings'), schoolId);
      }

      if (_checkIfUrlStartsWithState(url, 'exams', schoolId)) {
        /**
         * When we navigate to any exams page, we want to highlight the Course menu item.
         */
        return _getMenuItemByUrl(BASE_PATH(schoolId, 'courses'), schoolId);
      }

      return _getMenuItemByUrl(url, schoolId);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
}

const BASE_PATH = (schoolId: string, state: string): string =>
  `/${schoolId}/${state}`;

function _checkIfUrlStartsWithState(
  url: string,
  state: string,
  schoolId: string,
): boolean {
  return url.startsWith(BASE_PATH(schoolId, state));
}

function _isOnBillingRoute(url: string, schoolId: string): boolean {
  return BILLING_ITEMS_TO_CHECK_FOR_MENU_PARAM.some((item) =>
    _checkIfUrlStartsWithState(url, item, schoolId),
  );
}

function _getFlattenedMenuItem(menuItems: Menu[]): Menu[] {
  return menuItems
    .flatMap((item) => {
      if (item.children) {
        return [item, ..._getFlattenedMenuItem(item.children)];
      }

      return [item];
    })
    .filter((item) => item.state);
}

function _getMenuItemByUrl(url: string, schoolId: string) {
  return FLAT_MENU_ITEMS.find((item): boolean => {
    if (item.state.includes('/')) {
      return url.includes(item.state.split('/')[1]);
    }

    return _checkIfUrlStartsWithState(url, item.state, schoolId);
  });
}
