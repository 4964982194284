import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';

import { SchoolAdminPermissionService } from '../../state/school-admin-permission.service';

@Injectable({
  providedIn: 'root',
})
export class SchoolAdminPermissionSyncGuard extends SyncGuard {
  constructor(protected service: SchoolAdminPermissionService) {
    super();
  }

  protected sync(params: Params) {
    return this.service.syncAdminPermissions(params['schoolId']);
  }

  protected deactivateCallback(): void {
    this.service.removeActive();
  }
}
