import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TruncatePipe } from '@memberspot/frontend/shared/ui/tools';
import { School } from '@memberspot/shared/model/school';
import { NgIconComponent } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-school-logo',
  templateUrl: './school-logo.component.html',
  standalone: true,
  imports: [
    MatMenuModule,
    CommonModule,
    NgIconComponent,
    RouterLink,
    TruncatePipe,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolLogoComponent {
  @Input() school?: School;
  @Input() schools?: School[];

  @Output() itemClicked = new EventEmitter();

  createLink(schoolId?: string) {
    return schoolId ? schoolId : 'schools';
  }

  trackBy(index: number, item: School) {
    return item.id;
  }
}
