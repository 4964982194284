import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { RouteData } from '@memberspot/admin/shared/util/model';
import { BreakPointService } from '@memberspot/frontend/shared/feature/services';
import { SCROLL_POSITION } from '@memberspot/frontend/shared/util/tokens';
import { School } from '@memberspot/shared/model/school';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  auditTime,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  tap,
} from 'rxjs';

import { FullComponent } from '../full.component';

@UntilDestroy()
@Component({
  selector: 'app-full-view',
  templateUrl: './full-view.component.html',
  styles: [],
  standalone: true,
  imports: [FullComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullViewComponent {
  schoolId$: Observable<string> = this._schoolsQuery
    .selectActiveId()
    .pipe(auditTime(0));

  school$: Observable<School> = this._schoolsQuery.selectActive();
  schools$ = combineLatest([
    this._schoolsQuery
      .selectAllSchools()
      .pipe(filter((schools): schools is School[] => !!schools)),
    this._schoolsQuery.selectActiveId(),
  ]).pipe(
    map(([schools, activeId]) =>
      schools.filter((school) => school.id !== activeId),
    ),
  );

  maxWidth$ = this._routerQuery.selectData().pipe(
    map((data: RouteData) => (data?.maxWidth ? `${data.maxWidth}px` : null)),
    distinctUntilChanged(),
  );

  darkSidebar$ = this._authQuery.selectDarkSidebar();
  darkMode$ = this._authQuery.selectDarkMode().pipe(
    distinctUntilChanged(),
    tap((dark) => this.setDarkTheme(dark)),
  );

  auth$ = user(this._auth);

  isHandset$ = this._breakpointService.isHandset$;
  isLg$ = this._breakpointService.isLg$;

  constructor(
    private _schoolsQuery: SchoolsQuery,
    private _breakpointService: BreakPointService,
    private _auth: Auth,
    private _authQuery: AuthQuery,
    private _routerQuery: RouterQuery,
    @Inject(SCROLL_POSITION) public scrollPosition$: Observable<number>,
  ) {}

  setDarkTheme(dark: boolean) {
    const body = document.querySelector('body');

    if (dark) {
      body.classList.remove('isLightTheme');
      body.classList.add('isDarkTheme', 'dark');
    } else {
      body.classList.remove('isDarkTheme', 'dark');
      body.classList.add('isLightTheme');
    }
  }
}
