import { Inject, Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { IS_HANDSET } from '@memberspot/frontend/shared/feature/services';
import {
  BootInput,
  IntercomService,
} from '@memberspot/frontend/shared/util/intercom';
import { Customer } from '@memberspot/models';
import {
  AdminUserEndpoints,
  IntercomIdentityVerification,
} from '@memberspot/shared/model/admin-user';
import { School } from '@memberspot/shared/model/school';
import { combineLatest, filter, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminIntercomService {
  private booted = false;

  constructor(
    private intercomService: IntercomService,
    private afAuth: Auth,
    private schoolsQuery: SchoolsQuery,
    private customerQuery: CustomersQuery,
    private http: HttpBackendService,
    @Inject(IS_HANDSET) private isHandset: Observable<boolean>,
  ) {}

  boot(config: BootInput) {
    if (this.booted) {
      throw new Error('Intercom already booted');
    }

    this.intercomService.boot(config);
    this.listenToAuth();
    this.listenToActiveSchoolChange();
    this.listenToCustomerChange();
    this.booted = true;
  }

  private lookForHandset() {
    this.isHandset.subscribe((isHandset) => {
      this.intercomService.update({
        hide_default_launcher: isHandset,
      });
    });
  }

  private listenToAuth() {
    user(this.afAuth)
      .pipe(
        switchMap((afUser) =>
          combineLatest([
            of(afUser),
            this.http.generic<IntercomIdentityVerification>(
              AdminUserEndpoints.GET_INTERCOM_SECRET(),
            ),
          ]),
        ),
      )
      .subscribe(([afUser, verification]) => {
        if (afUser) {
          this.intercomService.update({
            name: afUser?.displayName,
            email: afUser?.email,
            user_id: afUser?.uid,
            createdAt: afUser?.metadata?.creationTime,
            user_hash: verification.hash,
          });
        } else {
          this.intercomService.shutdown();
          this.intercomService.boot({});
        }
      });
  }

  private listenToActiveSchoolChange() {
    this.schoolsQuery
      .selectActive()
      .pipe(filter((school): school is School => !!school))
      .subscribe((school) => {
        this.intercomService.update({
          company_id: school.id,
          company_name: school.name,
          schoolurl: `https://app.memberspot.de/${school.id}`,
        });
      });
  }

  private listenToCustomerChange() {
    this.customerQuery
      .selectActive()
      .pipe(filter((customer): customer is Customer => !!customer))
      .subscribe((customer) => {
        this.intercomService.update({
          customer_plan: customer.plan,
          customer_status: customer.status,
        });
      });
  }
}
