import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { SENTRY_CONFIG, SentryConfig } from './config/sentry-config';
import { SentryErrorHandlerService } from './sentry-error-handler.service';

@NgModule({
  imports: [CommonModule],
})
export class FrontendSharedUtilSentryModule {
  static forRoot(
    isProd: boolean,
    config: SentryConfig,
  ): ModuleWithProviders<FrontendSharedUtilSentryModule> {
    const prodProviders = isProd
      ? [{ provide: ErrorHandler, useClass: SentryErrorHandlerService }]
      : [];

    return {
      ngModule: FrontendSharedUtilSentryModule,
      providers: [
        {
          provide: SENTRY_CONFIG,
          useValue: config,
        },
        ...prodProviders,
        SentryErrorHandlerService,
      ],
    };
  }
}
