/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { map } from 'rxjs/operators';

export const leadQualifiedFnGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);

  return inject(AuthQuery)
    .selectProfileWithAuth()
    .pipe(
      map((p) => (p?.leadQualified ? true : router.parseUrl('qualify-lead'))),
    );
};
