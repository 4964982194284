import { Injectable } from '@angular/core';
import { docData, Firestore } from '@angular/fire/firestore';
import { AllCourses } from '@memberspot/shared/model/course';
import { doc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AllCoursesStore } from './all-courses.store';

@Injectable({ providedIn: 'root' })
export class AllCoursesService {
  constructor(
    private allCoursesStore: AllCoursesStore,
    private afs: Firestore,
  ) {}

  sync(schoolId: string) {
    return (
      docData(doc(this.afs, `allCourses/${schoolId}`)) as Observable<AllCourses>
    ).pipe(
      map((ac) => ac?.courses || {}),
      tap((entities) => this.allCoursesStore.set(entities)),
    );
  }

  reset() {
    this.allCoursesStore.reset();
  }
}
