import { Inject, Injectable } from '@angular/core';
import {
  SCHOOL_ID,
  SyncFirestoreGuard,
} from '@memberspot/frontend/shared/data-access/common';
import { Observable } from 'rxjs';

import { AllCoursesService } from '../all-courses.service';

@Injectable({
  providedIn: 'root',
})
export class SyncAllCoursesGuard extends SyncFirestoreGuard {
  constructor(
    service: AllCoursesService,
    @Inject(SCHOOL_ID) schoolId: Observable<string>,
  ) {
    super(service, schoolId);
  }
}
