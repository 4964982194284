import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

export abstract class SyncService {
  abstract sync: (schoolId: string) => Observable<any>;
  abstract reset: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class SyncFirestoreGuard {
  subs = new SubSink();

  constructor(
    protected service: SyncService,
    protected schoolId$: Observable<string>,
  ) {}

  canActivate(): boolean {
    this.subs.sink = this.schoolId$
      .pipe(switchMap((schoolId) => this.service.sync(schoolId)))
      .subscribe();

    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.service.reset();
    this.subs.unsubscribe();

    return true;
  }
}
