import { makeEnvironmentProviders } from '@angular/core';

import { UserTrackingService } from './user-tracking.service';
import {
  USER_TRACKING_CONFIG,
  UserTrackingConfig,
} from './user-tracking-config';

export function provideUserTracking(config: UserTrackingConfig) {
  return makeEnvironmentProviders([
    { provide: USER_TRACKING_CONFIG, useValue: config },
    UserTrackingService,
  ]);
}
