<ng-container *transloco="let t">
  <h1 class="heading-new mb-2 text-center">
    {{ t('newSoftware.versionTooOldTitle') }}
  </h1>
  <div class="text-sm text-gray-500">
    <div
      class="text-center"
      [innerHTML]="t('newSoftware.versionTooOldText')"
    ></div>
  </div>
  <div class="mt-6 flex w-full flex-col gap-3 sm:flex-row">
    <button
      class="btn btn-primary w-full"
      cdkFocusInitial
      (click)="dialogRef.close(true)"
    >
      {{ t('common.confirm') }}
    </button>
  </div>
</ng-container>
