<ng-container *transloco="let t">
  <div
    class="m-5 rounded-lg border border-transparent bg-gray-900 px-5 py-3 shadow-xl"
  >
    <div
      class="flex flex-col items-center space-y-4 sm:flex-row sm:space-x-10 sm:space-y-0"
    >
      <div
        class="text-dwhite text-center text-sm font-medium"
        [innerHTML]="t('newSoftware.newSoftwarePopupNoTimer')"
      >
        <!-- [innerHTML]="
          isAdmin
            ? t('newSoftware.newSoftwarePopupNoTimer')
            : t('newSoftware.newSoftwarePopup', { timeToGo: (timer$ | async) || 15 })
        " -->
        <!-- {{ t('newSoftware.[matTooltip]=""newSoftwarePopup', { timeToGo: (timer$ | async) || 15 }) }} -->
        <!-- Eine neue Version ist verfügbar <br />
        App wird in {{ (timer$ | async) || 15 }}
        Sekunden aktualisiert -->
      </div>

      <div class="flex items-center justify-center space-x-4">
        <button
          (click)="refresh()"
          type="button"
          class="btn btn-primary border-0"
        >
          {{ t('text.refresh') }}
        </button>

        <button
          [matTooltip]="t('newSoftware.snooze')"
          type="button"
          (click)="snooze()"
          class="flex items-center text-gray-400"
        >
          <ng-icon name="hero-x-mark" class="text-xl"></ng-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
