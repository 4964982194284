import { inject, Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { DialogBaseService } from '@memberspot/frontend/shared/util/dialog';
import { APP_VERSION } from '@memberspot/frontend/shared/util/tokens';
import { WINDOW } from '@ng-web-apis/common';
import { filter, switchMap, timer } from 'rxjs';

import { SoftwareUpdateService } from './software-update.service';
import { UnsupportedVersionDialogComponent } from './unsupported-version/unsupported-version-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UnsupportedVersionService {
  private _window = inject(WINDOW);
  private _appversion = inject(APP_VERSION);
  private _swUpdateService = inject(SoftwareUpdateService);
  private _dialogService = inject(DialogBaseService);
  private _httpService = inject(HttpBackendService);
  // private _appRef = inject(ApplicationRef);

  initUnsupportedVersionCheck() {
    // const appIsStable$ = this._appRef.isStable.pipe(
    //   rxDebug('appIsStable'),
    //   first((isStable) => isStable === true)
    // );

    const everyThreeHours$ = timer(0, 3 * 60 * 60 * 1000);

    // const everyThreeHoursOnceAppIsStable$ = concat(
    //   appIsStable$,
    //   everyThreeHours$
    // ).pipe(filter((upt) => upt !== true));

    // everyThreeHoursOnceAppIsStable$
    everyThreeHours$
      .pipe(
        switchMap(() => this._httpService.get('/min-version')),
        filter(({ version }) => {
          console.log(
            'min. required version',
            version,
            'current version',
            this._appversion,
          );

          // we can parse als float, because patch versions should never be breaking
          const parsedMinVersion = parseFloat(version);
          const parsedCurrentVersion = parseFloat(this._appversion);

          if (parsedMinVersion > parsedCurrentVersion) {
            return true;
          }

          return false;
        }),
        switchMap(() =>
          this._dialogService.open(UnsupportedVersionDialogComponent),
        ),
        switchMap(() => this._swUpdateService.newUpdateReady$),
      )
      .subscribe(async (newUpdateReady: boolean) => {
        if (!newUpdateReady) {
          console.log('unregsiter servie worker and update app');
          const registrations =
            await this._window.navigator.serviceWorker.getRegistrations();

          await Promise.all(registrations.map((r) => r.unregister()));
        }

        this._window.location.reload();
      });
  }
}
