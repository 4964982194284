import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';

import { AllExamsService } from '../state/all-exams.service';

@Injectable({
  providedIn: 'root',
})
export class AllExamsSyncGuard extends SyncGuard {
  constructor(private service: AllExamsService) {
    super();
  }

  protected sync(params: Params) {
    return this.service.sync(params['schoolId']);
  }
}
