import { inject, Provider } from '@angular/core';
import { CustomUserPropertiesStoreService } from '@memberspot/admin/shared/data-access/custom-property';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { CUSTOM_PROPERTIES_TOKEN } from '@memberspot/frontend/shared/util/tokens';
import { switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

export const CUSTOM_PROPERTIES_PROVIDER: Provider = {
  provide: CUSTOM_PROPERTIES_TOKEN,
  useFactory: () => {
    const schoolsQuery = inject(SchoolsQuery);
    const customPropertiesStore = inject(CustomUserPropertiesStoreService);

    return schoolsQuery.selectActiveIdAfterLoading().pipe(
      filter(
        (id: string | null | undefined): id is string =>
          id !== null && id !== undefined && id?.length > 0,
      ),
      switchMap((id: string) =>
        customPropertiesStore.selectCustomPropertiesForSchool(id),
      ),
    );
  },
  deps: [SchoolsQuery, CustomUserPropertiesStoreService],
};
