<ng-container *transloco="let t">
  <div class="flex flex-col items-center">
    <h3 class="heading-new">{{ t('terms.title') }}</h3>
    <div class="subtitle-new">{{ t('terms.subtitle') }}</div>
  </div>

  <div class="subtitle-new mt-5" innerHtml="{{ t('terms.text') }}"></div>

  <div class="mt-6 flex items-center gap-3">
    <button (click)="closeDialog(false)" class="btn btn-white w-full">
      {{ t('text.decline') }}
    </button>
    <button (click)="closeDialog(true)" class="btn btn-primary w-full">
      {{ t('text.accept') }}
    </button>
  </div>
</ng-container>
