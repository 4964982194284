import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { TokenReturnData } from '@memberspot/models';
import { AdminUserEndpoints } from '@memberspot/shared/model/admin-user';
import { BehaviorSubject, first, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AhaService {
  private token$ = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpBackendService) {}

  async goToBoardNew() {
    this.token$
      .pipe(
        first(),
        switchMap((token) => (token ? of(token) : this.getToken())),
      )
      .subscribe((t) => this.openBoardLink(t));
  }

  private openBoardLink(token: string) {
    const url = `https://feedback.memberspot.io/auth/jwt/callback?jwt=${token}`;
    const win = window.open(url, '_blank');

    win?.focus();
  }

  private getToken() {
    return this.http
      .generic<TokenReturnData>(AdminUserEndpoints.GET_AHA_JWT())
      .pipe(
        map((td) => td.token),
        tap((t) => this.token$.next(t)),
      );
  }
}
