import { ENVIRONMENT_INITIALIZER, DestroyRef, inject, makeEnvironmentProviders } from '@angular/core';
import { injectQueryClient } from '@ngneat/query';
function provideQueryDevTools(devToolOptions = {}) {
  return makeEnvironmentProviders([{
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue() {
      const queryClient = injectQueryClient();
      const destroyRef = inject(DestroyRef);
      import('./ngneat-query-devtools-devtools-OTAO4CAQ.mjs').then(m => {
        m.queryDevtools({
          queryClient,
          destroyRef,
          ...devToolOptions
        });
      });
    }
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideQueryDevTools };
