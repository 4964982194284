const harmlessErrors = [
  // { name: 'FirebaseError', message: 'Missing or insufficient permissions.' },
  {
    name: 'Error',
    message:
      "The user's credential is no longer valid. The user must sign in again.",
  },
  {
    name: 'DOMException',
    message: 'The play() request was interrupted by a call to pause().',
  },
  {
    name: 'AbortError',
    message:
      'The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
  },
  { name: 'AbortError', message: 'The operation was aborted.' },
];

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

export const isHarmlessError = (name: string, message: string): boolean => {
  isChunkReloadErr(message);
  const harmlessErrorsFound = harmlessErrors.some(
    (el) => el.name === name && el.message === message,
  );

  return harmlessErrorsFound;
};

export const isChunkReloadErr = (message: string) => {
  if (chunkFailedMessage.test(message)) {
    console.log('chunk load error, reload');
    // window.location.reload();
  }
};
