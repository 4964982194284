import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { LanguageService } from '@memberspot/admin/shared/data-access/auth';
import { AdminIntercomService } from '@memberspot/admin/shared/service/intercom';
import { DpaBannerService } from '@memberspot/admin/shared/service/system-messages';
import { SessionService } from '@memberspot/frontend/auth/feature/login';
import {
  PwaInstallService,
  PwaUpdateService,
} from '@memberspot/frontend/shared/feature/pwa';
import { SidenavService } from '@memberspot/frontend/shared/feature/services';
import { NavBackService } from '@memberspot/frontend/shared/util/common';
import { TwaService } from '@memberspot/frontend/shared/util/twa';
import { VersionService } from '@memberspot/frontend/shared/util/version';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';
import { BreadcrumbDefinition, BreadcrumbService } from 'xng-breadcrumb';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'memberspot App';
  loaded = false;

  constructor(
    private _router: Router,
    private _translocoService: TranslocoService,
    private _gtmService: GoogleTagManagerService,
    private _languageService: LanguageService,
    private _adminIntercomService: AdminIntercomService,
    private _twaService: TwaService,
    private _breadcrumbService: BreadcrumbService,
    private _title: Title,
    private _dpaBannerService: DpaBannerService,
    // Do not delete these unused imports, they are needed for global init
    private _sidenavService: SidenavService,
    private _session: SessionService,
    private _pwaService: PwaInstallService,
    private _pwaServiceNew: PwaUpdateService,
    private _navBackService: NavBackService,
    private _versionService: VersionService,
  ) {}

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((event) => {
        if (!this.loaded) {
          console.log('page loaded');
        }

        this.loaded = true;

        if (event instanceof NavigationEnd && environment.production) {
          this._gtmService.pushTag({
            event: 'page',
            pageName: event.url,
          });
        }
      });

    this._breadcrumbService.breadcrumbs$
      .pipe(
        switchMap((b) => {
          const tran = this._getTranslateObject(b);

          return tran.translate
            ? this._translocoService.selectTranslate(tran.label)
            : of(tran.label);
        }),
        distinctUntilChanged(),
      )
      .subscribe((tranObj: string) => {
        this._title.setTitle(tranObj || 'memberspot');
      });

    this._languageService.startLanguageStream();
    this._initTranslocco();
    setTimeout(() => {
      this._adminIntercomService.boot({});
      this._twaService.init();
      this._dpaBannerService.init();
    }, 1000);
  }

  private _initTranslocco(): void {
    this._translocoService
      .selectTranslate('auth.password')
      .pipe(first())
      .subscribe();
  }

  private _getTranslateObject = (
    b: BreadcrumbDefinition[],
  ): {
    label: string;
    translate: boolean;
  } => {
    if (b.length === 0) {
      return { label: '', translate: false };
    }

    if (b[b.length - 1].info === 'translate') {
      return { label: b[b.length - 1].label as string, translate: true };
    } else {
      return { label: b[b.length - 1].label as string, translate: false };
    }
  };
}
