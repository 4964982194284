import { ModuleWithProviders, NgModule } from '@angular/core';
import { Language } from '@memberspot/shared/model/types';
import { provideTransloco, TranslocoModule } from '@ngneat/transloco';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

import { getBrowserLanguage } from './get-browser-language';
import { ImportLoader } from './loaders/transloco-import-loader';
import { TranslationConfig } from './model/translation-config';

@NgModule({
  exports: [TranslocoModule],
})
export class TranslocoRootModule {
  static forRoot(
    config: TranslationConfig,
  ): ModuleWithProviders<TranslocoRootModule> {
    return {
      ngModule: TranslocoRootModule,
      providers: [
        provideTransloco({
          config: {
            availableLangs: Object.values(Language),
            defaultLang:
              config.scope === 'admin'
                ? getBrowserLanguage(window)
                : Language.De,
            prodMode: config.production,
            fallbackLang: Language.De,
            reRenderOnLangChange: config.canReRender ?? false,
          },
          loader: config.defaultLoader || ImportLoader,
        }),
        provideTranslocoMessageformat(),
      ],
    };
  }
}
