import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { scopeLoader } from '@memberspot/frontend/shared/util/translation';
import { TermsDto } from '@memberspot/shared/model/admin-user';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'mspot-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styles: [],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'terms',
        loader: scopeLoader(
          (lang: string) => import(`../../assets/i18n/${lang}.json`),
        ),
        canReRender: true,
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsDialogComponent {
  constructor(public dialogRef: MatDialogRef<TermsDialogComponent, TermsDto>) {}

  closeDialog(accepted: boolean) {
    this.dialogRef.close({ accepted });
  }
}
