import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  CommunityData,
  CommunitySpaceEndpoints,
} from '@memberspot/shared/model/community';

@Injectable({
  providedIn: 'root',
})
export class CommunityDataService {
  constructor(private http: HttpBackendService) {}

  getCommunityData(schoolId: string) {
    return this.http.generic<CommunityData>(
      CommunitySpaceEndpoints.GET_COMMUNITY_DATA(schoolId),
    );
  }
}
