<ng-container *transloco="let t">
  @if (breadcrumbs$ | async; as breadcrumbs) {
    @if (breadcrumbs.length > 1) {
      <a
        class="-ml-1 flex items-center gap-1.5 dark:text-gray-500"
        [routerLink]="breadcrumbs[breadcrumbs.length - 2].routeLink"
        [queryParams]="breadcrumbs[breadcrumbs.length - 2].queryParams || {}"
      >
        <ng-icon name="hero-chevron-left" class="text-xl"></ng-icon>
        <span class="truncate font-medium">
          {{
            breadcrumbs[breadcrumbs.length - 2].info
              ? t($any(breadcrumbs[breadcrumbs.length - 2].label))
              : breadcrumbs[breadcrumbs.length - 2].label
          }}
        </span>
      </a>
    } @else {
      <ng-content></ng-content>
    }
  }
</ng-container>
