import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { delay, merge } from 'rxjs';

import { SoftwareUpdateService } from './software-update.service';
import { SoftwareUpdateComponent } from './software-update/software-update.component';

@Injectable({
  providedIn: 'root',
})
export class PwaUpdateService {
  compRef?: ComponentRef<SoftwareUpdateComponent>;

  constructor(
    private _overlay: Overlay,
    private _softwareUpdateService: SoftwareUpdateService,
  ) {
    this._initListener();
  }

  private _initListener() {
    this._softwareUpdateService.snooze$
      .pipe()
      .subscribe(() => this._destroyUpdateNoti());

    merge(
      this._softwareUpdateService.updateAvailable$,
      this._softwareUpdateService.snooze$.pipe(delay(300000)),
    )
      .pipe(
        // delay 3 hours
        delay(1000 * 60 * 60 * 3),
      )
      .subscribe(() => this._showUpdateNoti());
  }

  private _showUpdateNoti() {
    const positionStrategy = this._overlay
      .position()
      .global()
      .bottom()
      .centerHorizontally();

    const overlayRef = this._overlay.create({
      positionStrategy,
    });
    const swUptPortal = new ComponentPortal(SoftwareUpdateComponent);

    this.compRef = overlayRef.attach(swUptPortal);
  }

  private _destroyUpdateNoti() {
    this.compRef?.destroy();
  }
}
