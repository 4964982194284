import { InjectionToken } from '@angular/core';

export const SENTRY_CONFIG = new InjectionToken<SentryConfig>('');

export interface SentryConfig {
  dsn: string;
  version: string;
  envTag: string;
  hideErrors?: boolean;
  ignoreErrors?: (string | RegExp)[];
}
