import { ModuleWithProviders, NgModule } from '@angular/core';

import { Version } from './version.model';
import { VersionService } from './version.service';
import { VERSION } from './version.tokens';

@NgModule({})
export class VersionModule {
  static setEnvironment(environment: {
    version: Version;
  }): ModuleWithProviders<VersionModule> {
    return {
      ngModule: VersionModule,
      providers: [
        { provide: VERSION, useValue: environment.version },
        VersionService,
      ],
    };
  }
}
