import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';
import {
  SpaceGroupSyncService,
  SpaceSyncService,
} from '@memberspot/frontend/shared/data-access/spaces';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CommunityDataService } from '../community-data.service';

@Injectable({
  providedIn: 'root',
})
export class SpacesSyncGuard extends SyncGuard {
  constructor(
    protected spaceGroupsSyncService: SpaceGroupSyncService,
    protected spaceSyncService: SpaceSyncService,
    protected communityDataService: CommunityDataService,
  ) {
    super();
  }

  protected sync(params: Params) {
    return this.communityDataService
      .getCommunityData(params['schoolId'])
      .pipe(
        switchMap((data) =>
          combineLatest([
            this.spaceGroupsSyncService.saveGroups(data.groups),
            this.spaceSyncService.saveSpaces(data.spaces),
          ]),
        ),
      );
  }
}
