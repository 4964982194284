import { inject, InjectionToken, Provider } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import {
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  shareReplay,
} from 'rxjs';

export const SCROLL_POSITION = new InjectionToken<Observable<number>>(
  'A stream with current scroll y position',
);

export const SCOLLED_PROVIDER: Provider = {
  provide: SCROLL_POSITION,
  useFactory: () => {
    const injectedWindow = inject(WINDOW);

    return fromEvent(injectedWindow, 'scroll', { passive: true }).pipe(
      map(() => window.scrollY),
      distinctUntilChanged(),
      shareReplay(1),
    );
  },
};
