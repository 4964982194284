<app-full-layout
  [schoolId]="schoolId$ | async"
  [school]="school$ | async"
  [schools]="schools$ | async"
  [user]="auth$ | async"
  [darkSidebar]="darkSidebar$ | async"
  [darkMode]="darkMode$ | async"
  [isHandset]="isHandset$ | async"
  [isLg]="isLg$ | async"
  [maxWidth]="maxWidth$ | async"
  [scrolled]="(scrollPosition$ | async) > 0"
>
</app-full-layout>
