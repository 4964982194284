import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AdminSharedUiMessageStatusModule } from '@memberspot/admin/shared/ui/message-status';
import { FrontendAuthFeatureLoginModule } from '@memberspot/frontend/auth/feature/login';
import { FrontendSearchUiSearchInputModule } from '@memberspot/frontend/search/ui/search-input';
import { FrontendSharedUiControlsModule } from '@memberspot/frontend/shared/ui/controls';
import { FrontendSharedUiMenuModule } from '@memberspot/frontend/shared/ui/menu';
import { ToolsModule } from '@memberspot/frontend/shared/ui/tools';
import { FrontendSharedUtilImageModule } from '@memberspot/frontend/shared/util/image';
import { NgIconsModule } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';
import { BreadcrumbModule } from 'xng-breadcrumb';

const modules = [
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  HttpClientModule,
  ToolsModule,
];

const materialModules = [MatMenuModule];

@NgModule({
  imports: [
    CommonModule,
    ...modules,
    BreadcrumbModule,
    TranslocoModule,
    PortalModule,
    FrontendSharedUiControlsModule,
    FrontendAuthFeatureLoginModule,
    NgIconsModule,
    FrontendSharedUtilImageModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDividerModule,
    FrontendSearchUiSearchInputModule,
    FrontendSharedUiMenuModule,
    ...materialModules,
    AdminSharedUiMessageStatusModule,
  ],
  exports: [...modules, MatProgressBarModule, MatDividerModule],
})
export class SharedModule {}
