<ng-container *transloco="let t">
  @if (breadcrumbs$ | async; as breadcrumbs) {
    @if (breadcrumbs.length > 1) {
      @if (isHandset$ | async) {
        <div class="flex items-center">
          <div class="flex items-center text-sm text-medium text-gray-900/60">
            <a
              class="flex items-center mobile-back"
              [routerLink]="breadcrumbs[breadcrumbs.length - 2]?.routeLink"
              [queryParams]="breadcrumbs[breadcrumbs.length - 2]?.queryParams"
            >
              <ng-icon name="hero-chevron-left" class="mr-1"></ng-icon>
              <span class="font-medium">
                {{
                  breadcrumbs[breadcrumbs.length - 2]?.info
                    ? t($any(breadcrumbs[breadcrumbs.length - 2]?.label))
                    : breadcrumbs[breadcrumbs.length - 2]?.label
                }}
              </span>
            </a>
          </div>
        </div>
      } @else {
        <nav class="flex mx-auto" aria-label="Breadcrumb">
          <ol role="list" class="flex space-x-4">
            @for (
              breadcrumb of breadcrumbs;
              track breadcrumb.routeLink;
              let isFirst = $first;
              let isLast = $last
            ) {
              <ng-container
                *ngTemplateOutlet="
                  isFirst
                    ? breadCrumbFirstIcon
                    : isLast
                      ? breadCrumbElLast
                      : breadCrumbEl;
                  context: { $implicit: breadcrumb }
                "
              ></ng-container>
            }
          </ol>
        </nav>
      }
    }
  }

  <ng-template #breadCrumbFirstIcon let-breadcrumb>
    <li>
      <div>
        <a
          [routerLink]="breadcrumb.routeLink"
          [queryParams]="breadcrumb.queryParams"
          class="flex items-center text-gray-900/40 hover:text-gray-900/60"
        >
          <ng-icon
            [name]="breadcrumb.icon"
            class="flex-shrink-0 text-xl"
          ></ng-icon>
        </a>
      </div>
    </li>
  </ng-template>

  <ng-template #breadCrumbEl let-breadcrumb>
    <li>
      <div class="flex items-center">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gray-900/40"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <a
          [routerLink]="breadcrumb.routeLink"
          [queryParams]="breadcrumb.queryParams"
          class="ml-4 text-sm font-medium text-gray-900/60 hover:text-gray-900/80"
          aria-current="page"
          >{{
            breadcrumb.info === 'translate'
              ? t(breadcrumb.label)
              : breadcrumb.label
          }}</a
        >
      </div>
    </li>
  </ng-template>

  <ng-template #breadCrumbElLast let-breadcrumb>
    <li>
      <div class="flex items-center">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gray-900/40"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span
          class="ml-4 text-sm cursor-default text-gray-900/40"
          aria-current="page"
          >{{
            breadcrumb.info === 'translate'
              ? t(breadcrumb.label)
              : breadcrumb.label
          }}</span
        >
      </div>
    </li>
  </ng-template>
</ng-container>
