import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isHarmlessError } from '@memberspot/shared/util/constants';
import { captureException, Event, init, withScope } from '@sentry/angular-ivy';

import { SENTRY_CONFIG, SentryConfig } from './config/sentry-config';
import { denyUrls } from './util/deny-urls';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService {
  constructor(@Inject(SENTRY_CONFIG) public config: SentryConfig) {
    init({
      dsn: config.dsn,
      release: `memberhub@${config.version}`,
      environment: config.envTag,
      beforeSend: (event, hint) => {
        if (!hint?.originalException) {
          return null;
        }

        if (isInjectedCode(event)) {
          return null;
        }

        if (isHandeledInGlobalErrorHandler(hint?.originalException)) {
          this.handleError(hint?.originalException);

          return null;
        }

        return event;
      },
      ignoreErrors: config.ignoreErrors,
      denyUrls: denyUrls,
      tracesSampleRate: 1,
    });
  }

  handleError(error: any) {
    if (!this.config?.hideErrors) {
      console.error(error);
    }

    if (!error) {
      console.error('No error object');

      return;
    }

    if (this._isHttpError(error) && this._shouldIgnoreStatus(error)) {
      return;
    }

    if (
      !this._isHttpError(error) &&
      !isHarmlessError(error.name, error.message)
    ) {
      this._handleExeption(error);

      return;
    }

    if (this._isHttpError(error)) {
      this._handleHttpError(error);
    }
  }

  private _isHttpError(error: any) {
    return error instanceof HttpErrorResponse;
  }

  private _shouldIgnoreStatus(error: any) {
    const status = error?.status || error?.statusCode;

    return status === 401 || status === 403 || status === 504;
  }

  private _getFormatedErrorMessage(error: any) {
    return `${error?.status} ${error?.statusText}`;
  }

  private _handleHttpError(error: any) {
    withScope((scope) => {
      scope.setTag('httpStatus', error?.status || 'no status');
      scope.setExtra('originalError', error?.error);

      if (error?.reqTime) {
        scope.setExtra('requestTime', error?.reqTime);
      }

      captureException(new Error(this._getFormatedErrorMessage(error)), scope);
    });
  }

  private _handleExeption(error: any) {
    withScope((scope) => {
      scope.setExtra(
        'originalError',
        error.originalError || error.error || error,
      );
      captureException(new Error(error.message), scope);
    });
  }
}

const isHandeledInGlobalErrorHandler = (error: any): boolean => {
  if (!error) {
    return false;
  }

  if (error instanceof HttpErrorResponse) {
    return true;
  }

  if (isHarmlessError(error.name, error.message)) {
    return true;
  }

  return false;
};

const isInjectedCode = (event: Event) => {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) {
    return false;
  }

  const lastFilename = frames[frames.length - 1]?.filename;

  if (lastFilename === '<anonymous>' || lastFilename === '[native code]') {
    return true;
  }

  return false;
};
