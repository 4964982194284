@if (breadcrumbs$ | async; as breadcrumbs) {
  @if (breadcrumbs.length > 1) {
    <div
      class="mx-auto pl-3 pt-6 lg:px-4"
      [style.max-width]="maxWidth$ | async"
    >
      <mspot-breadcrumb></mspot-breadcrumb>
    </div>
  }
}
