import { inject, Injectable } from '@angular/core';

import { VERSION } from './version.tokens';

@Injectable({ providedIn: 'platform' })
export class VersionService {
  version = inject(VERSION);
  constructor() {
    this._message();
  }

  private _message(): void {
    console.info(
      `%c==================================
 Memberspot
==================================
 Hello and welcome!

 %cBuild Information:
 ------------------
 Version: ${this.version.tag}
 Date: ${this.version.build}
 Hash: ${this.version.sha}
 Environment: ${this.version.env}
 Release Manager: ${this.version.actor}

%c==================================
 Thank you for using our product!
`,
      'font-weight: bold; font-size: 12px; color: #2355c6',
      'font-size: 11px; color: #2355c6',
      'font-weight: bold; font-size: 12px; color: #2355c6',
    );
  }
}
