import { InjectionToken } from '@angular/core';

export const INTERCOM_CONFIG = new InjectionToken<IntercomConfig>(
  'Intercom config',
);

export interface IntercomConfig {
  app_id: string;
  api_base: string;
  active: boolean;
  updateOnRouterChange?: boolean;
  /**
   * Customize left or right position of messenger
   */
  alignment?: 'left' | 'right';
  /**
   * Customize horizontal padding
   */
  horizontal_padding?: number;

  /**
   * Customize vertical padding
   */
  vertical_padding?: number;

  /**
   * arbitrarily localize your intercom messenger
   */
  language_override?: string;
}
