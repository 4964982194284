import { Injectable } from '@angular/core';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  DialogBaseService,
  DialogSize,
} from '@memberspot/frontend/shared/util/dialog';
import {
  AdminUser,
  AdminUserEndpoints,
  TermsDto,
} from '@memberspot/shared/model/admin-user';
import { filter, first, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  constructor(
    private dialogService: DialogBaseService,
    private http: HttpBackendService,
    // private noti: NotiService,
    private authQuery: AuthQuery,
  ) {}

  checkIfCurrentTermsAccepted() {
    this.authQuery
      .selectProfile()
      .pipe(
        filter((u) => !!u),
        first(),
        switchMap((u) =>
          (u.termsVersion || 0) >= 2 ? of(true) : this.openTermsDialog(u),
        ),
      )
      .subscribe();
  }

  openTermsDialog(user: AdminUser) {
    return this.dialogService
      .open<any, any, TermsDto>(TermsDialogComponent, DialogSize.SIZE_500)
      .pipe(
        switchMap((dto) =>
          dto.accepted || !user.declinedAt
            ? this.http.generic(AdminUserEndpoints.UPDATE_TERMS(dto))
            : of(true),
        ),
      );
  }
}
