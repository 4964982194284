<mspot-banner-base-style
  class="dark:text-white"
  *transloco="let t"
  [ngClass]="{
    'bg-gray-100 text-gray-800': banner().variant === 'info',
    'bg-red-100 text-red-800 dark:bg-red-800': banner().variant === 'error',
    'bg-green-100 text-green-800 dark:bg-green-800':
      banner().variant === 'success',
    'bg-yellow-100 text-orange-800 dark:bg-yellow-700':
      banner().variant === 'warning',
    'bg-blue-950 text-white': banner().variant === 'dpa'
  }"
>
  <div class="flex flex-col flex-1 gap-2 sm:flex-row sm:gap-4">
    @if (isMobile()) {
      <div class="flex gap-2.5 items-center">
        <ng-container *ngTemplateOutlet="icon"></ng-container>

        <div class="flex-1 overflow-hidden">
          <ng-container *ngTemplateOutlet="title"></ng-container>
        </div>

        @if (banner().canClose) {
          <ng-container *ngTemplateOutlet="close"></ng-container>
        }
      </div>

      <ng-container *ngTemplateOutlet="description"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="icon"></ng-container>
      <div class="flex flex-col">
        <ng-container *ngTemplateOutlet="title"></ng-container>

        <ng-container *ngTemplateOutlet="description"></ng-container>
      </div>
    }
  </div>

  <div class="flex items-center gap-2">
    @if (banner().action) {
      <a
        [routerLink]="banner().action?.link"
        class="flex items-center self-start h-10 px-4 py-2 transition-colors border border-current rounded-lg sm:self-center hover:bg-black/15 dark:hover:bg-white/15 sm:py-1"
      >
        <span class="whitespace-nowrap">
          {{ t(banner().action?.labelKey || '', banner().action?.labelParams) }}
        </span>
      </a>
    }

    @if (banner().canClose) {
      <div class="hidden sm:block">
        <ng-container *ngTemplateOutlet="close"></ng-container>
      </div>
    }
  </div>

  <ng-template #title>
    <h3 class="flex-1 text-lg font-medium break-all">
      {{ t(banner().titleKey, banner().titleParams) }}
    </h3>
  </ng-template>

  <ng-template #description>
    <p class="text-sm">
      {{ t(banner().descriptionKey, banner().descriptionParams) }}
    </p>
  </ng-template>

  <ng-template #icon>
    <ng-icon
      [name]="getIcon(banner().variant)"
      class="text-2xl sm:text-[40px] sm:self-center"
    />
  </ng-template>

  <ng-template #close>
    <button
      class="flex items-center ml-4 hover:bg-black/10 dark:hover:bg-white/10 transition-colors p-1.5 rounded-full"
      (click)="closeBanner.emit()"
    >
      <ng-icon name="hero-x-mark" class="text-2xl" />
    </button>
  </ng-template>
</mspot-banner-base-style>
