/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Auth, User, user as firebaseUser } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AuthQuery,
  AuthService,
} from '@memberspot/admin/shared/data-access/auth';
import { SchoolsService } from '@memberspot/admin/shared/data-access/schools';
import { setSentryUserInfo } from '@memberspot/frontend/shared/util/sentry';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  subs = new SubSink();
  synced = false;
  setLoaded = false;
  pushedGtmTag = false;

  constructor(
    private _auth: Auth,
    private _router: Router,
    private _authService: AuthService,
    private _schoolsService: SchoolsService,
    private _googleTagManagerService: GoogleTagManagerService,
    private _authQuery: AuthQuery,
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    if (!this.synced) {
      if (!this.setLoaded) {
        this._authService.setLoading(true);
      }

      this.subs.sink = this._authService
        .sync()
        .pipe(
          tap((res) => {
            if (res && !this.setLoaded) {
              this._authService.setLoading(false);
              this.setLoaded = true;
            }
          }),
        )
        .subscribe();
      this.subs.sink = this._schoolsService.syncSchoolsWithAccess().subscribe();
      this.synced = true;
    }

    return firebaseUser(this._auth).pipe(
      tap((user: User) => {
        setSentryUserInfo(user?.uid);

        if (environment.production && !this.pushedGtmTag) {
          this._googleTagManagerService.pushTag({
            user_email: user?.email,
            user_name: user?.displayName,
          });
          this.pushedGtmTag = true;
        }
      }),
      switchMap((user: User) => {
        if (!user) {
          return of(this._router.parseUrl('/auth/login'));
        } else if (state.url.includes('/auth/action?')) {
          return of(true);
        } else if (!user.emailVerified) {
          return of(this._router.parseUrl('/auth/verify'));
        } else if (state.url === '/') {
          return this.returnToLastSchool();
        }

        return of(true);
      }),
    );
  }

  returnToLastSchool() {
    return this._authQuery
      .selectProfileWithAuth()
      .pipe(
        map((p) =>
          p?.schoolId
            ? this._router.parseUrl(p.schoolId)
            : this._router.parseUrl('schools'),
        ),
      );
  }

  canDeactivate(
    _component: any,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot,
  ):
    | boolean
    | import('@angular/router').UrlTree
    | Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    this.synced = false;
    this.subs.unsubscribe();

    return true;
  }
}
