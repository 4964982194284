<ng-container *transloco="let t; read: translationKey()">
  <a
    [routerLink]="[schoolId()]"
    class="btn bg-mspot-primary-50 border-1 border-mspot-primary-100 flex flex-row items-center justify-center gap-2 rounded-md p-2"
  >
    <span
      class="text-mspot-neutral-950 text-medium hidden font-medium md:inline-block"
    >
      {{ t('globalInfo') }}
    </span>
    <div
      class="text-10 leading-2 text-mspot-white bg-mspot-primary-500 rounded-sm px-1.5 py-1 font-medium"
    >
      {{ progress().percent }}&percnt;
    </div>
  </a>
</ng-container>
